import React, { useState } from 'react'

import InquiryListWithSearchForm from './InquiryListWithSearchForm'

const InquiryListWidget = () => {
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  return (
    <div className="border p-2 w-full">
      <div>InquiryListWidget</div>
      <div>
        <InquiryListWithSearchForm
          controlledPageIndex={pageIndex}
          controlledPageSize={pageSize}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  )
}

export default InquiryListWidget
