import { FiActivity, FiClock, FiExternalLink, FiUsers } from 'react-icons/fi'
import SectionTitle from '../components/dashboard/section-title'
import Widget1 from '../components/dashboard/widget-1'
import InquiryListWidget from '../features/inquiry/components/InquiryListWidget'
import ProtectRoute from '@shared/hoc/ProtectRoute'

const Index = () => {
  return (
    <ProtectRoute>
      <SectionTitle title="Overview" subtitle="Dashboard" />

      <p className="mb-4">대시보드 샘플입니다. 실제 데이터가 아닙니다.</p>

      <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
        {/*widget*/}
        <div className="w-full lg:w-1/4">
          <Widget1
            title="Users"
            description={588}
            right={<FiUsers size={24} className="stroke-current text-gray-500" />}
          />
        </div>
        {/*widget*/}
        <div className="w-full lg:w-1/4">
          <Widget1
            title="Sessions"
            description={(1, 435)}
            right={<FiActivity size={24} className="stroke-current text-gray-500" />}
          />
        </div>
        {/*widget*/}
        <div className="w-full lg:w-1/4">
          <Widget1
            title="Bounce rate"
            description="40.5%"
            right={<FiExternalLink size={24} className="stroke-current text-gray-500" />}
          />
        </div>
        {/*widget*/}
        <div className="w-full lg:w-1/4">
          <Widget1
            title="Session duration"
            description="1m 24s"
            right={<FiClock size={24} className="stroke-current text-gray-500" />}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 bg-white">
        <InquiryListWidget />
      </div>
    </ProtectRoute>
  )
}
export default Index
