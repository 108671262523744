import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

const ProtectRoute: FC<any> = ({ children }) => {
  const router = useRouter()
  const goToLoginPage = async () => router.replace('/login')

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token')
    const refreshToken = localStorage.getItem('refresh_token')
    const refreshTokenExpiresAt = localStorage.getItem('refresh_token_expires_at')

    let isTokenAvailable: boolean = false
    if (
      accessToken !== null &&
      refreshToken !== null &&
      refreshTokenExpiresAt !== null &&
      parseInt(refreshTokenExpiresAt) > new Date().getTime()
    ) {
      isTokenAvailable = true
    }

    if (!isTokenAvailable) {
      console.log('ProtectRoute: isTokenAvailable === false')
      void goToLoginPage()
    }
  }, [])

  return (
    <>
      <div>{children}</div>
    </>
  )
}

export default ProtectRoute
